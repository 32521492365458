/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
}

div {
    padding: 0;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 100px;
}

@font-face {
    font-family: LineGothic;
    src: url("https://cf-static.shinycolors.moe/fonts/LINESeedJP_OTF_Bd.woff2") format("woff2");
}

.lineGothic {
    font-family: LineGothic;
}

.size18 {
    font-size: 18px;
}

.color_vocal {
    background-color: #ff6969 !important;
}

.color_dance {
    background-color: #7381ff !important;
}

.color_visual {
    background-color: #ffe75d !important;
}

.color_mental {
    background-color: #f27aff !important;
}

.color_skill {
    background-color: #ff9100 !important;
}
